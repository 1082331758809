import React, {useState} from 'react'

import {ProductStock} from '.'
import cn from 'classnames'
import TagManager from 'react-gtm-module'
import ErrorWarningFillIcon from 'remixicon-react/ErrorWarningFillIcon'
import HeartFillIcon from 'remixicon-react/HeartFillIcon'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import {media, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {User, VariantDetail} from '@festi/common/api/rest'
import {
  CheckButton,
  IconButton,
  TextButton,
} from '@festi/common/components/buttons'
import {AddToCartButton} from '@festi/common/components/cart'
import {Tooltip} from '@festi/common/components/common'
import {
  H1,
  H6,
  PSmall,
  PriceText,
  OldPriceText,
  DutyfreeText,
} from '@festi/common/components/typography'
import {useAuth, useComparison} from '@festi/common/contexts'
import {onDutyFree} from '@festi/common/constants/channels'
import {Inventory} from '@festi/common/utils/products'
import {AugmentedUserPrice} from '@festi/common/utils/rest'
import {fluidRange} from '@festi/common/utils/styles'
import {useWishlist} from '@festi/common/utils/wishlists'
import {formatPrice} from '@festi/utils/numbers'
import settings from '@festi/common/constants/settings'
import {getListingPrice} from '@festi/common/utils/price'

import {NoticeSticker, SeeMoreWrapper} from '../common'
import EnergyLabel from './EnergyLabel'
import NotificationSubscription from './NotificationSubscription'
import PriceHistoryModal from './PriceHistoryModal'
import ProductRichText from './ProductRichText'
import SellingPoint from './SellingPoint'
import QsPricer from './pricer/QsPricer'

const Wrapper = styled.div`
  scroll-behavior: smooth;
`

const BrandingWrapper = styled.div`
  padding: 15px 0;
  display: flex;
  align-items: center;

  ${media.sm`
    padding: 20px 0;
  `}
`

const BrandLogo = styled.img`
  ${fluidRange('height', '30px', '40px')}
  margin-right: 16px;
  display: flex;
`

const Sku = styled.span`
  font-size: 1rem;
  color: ${palette('ui40Solid')};
  font-weight: 500;
  line-height: 1;
`

const SellingPointsWrapper = styled.div`
  padding-top: 20px;
  margin-bottom: 30px;
`

const PricingWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const ActionsWrapper = styled.div`
  padding-top: 30px;

  button {
    margin-bottom: 8px !important;
  }

  ${media.md`
    button {
      margin-bottom: 16px !important;
    }
  `}
`

const DutyFreePriceWarning = styled(PSmall)`
  text-align: right;
  color: ${palette('ui60Solid')};
  margin: 2px 0;
`

const PriceHistoryBtnWrapper = styled.span`
  padding-top: 4px;
`

const EnergyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const EnergyLabelImageLink = styled.div`
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;

  > .wishlist {
    flex: 0 0 56px;
    border: 2px solid ${palette('border')};
  }
  > .comparison {
    flex: 1;
  }
`

const Margin = styled.div`
  margin: 20px 0;
`

interface PriceProps {
  sku: string
  price: number
  lowestPrice: number
  cityPrice: number | null
  cityBasePrice: number | null
  onOpenPriceHistory: () => void
}

function PriceInfo({
  price,
  cityPrice,
  lowestPrice,
  cityBasePrice,
  onOpenPriceHistory,
}: PriceProps): JSX.Element {
  const hasDiscount = lowestPrice < price
  const cityHasDiscount = cityPrice && cityPrice < cityBasePrice

  const showPriceHistory = true

  const OldPrice = ({hasDiscount}) => {
    return onDutyFree ? (
      <DutyfreeText>
        {!hasDiscount ? (
          <>
            <span>{'Almennt verð: '}</span>
            <OldPriceText className="large">
              {formatPrice(cityPrice)}
            </OldPriceText>
            {cityHasDiscount && (
              <DutyFreePriceWarning>
                Vinsamlega athugið að þessi <br /> vara er á tilboðsverði í
                bænum
              </DutyFreePriceWarning>
            )}
          </>
        ) : (
          <>
            <span>{'Verð áður: '}</span>
            <OldPriceText className="large">{formatPrice(price)}</OldPriceText>
          </>
        )}
      </DutyfreeText>
    ) : (
      !!hasDiscount && (
        <OldPriceText className="large">{formatPrice(price)}</OldPriceText>
      )
    )
  }

  return (
    <PricingWrapper>
      <OldPrice hasDiscount={hasDiscount} />
      <PriceText className="large">{formatPrice(lowestPrice)}</PriceText>

      {showPriceHistory && (
        <PriceHistoryBtnWrapper>
          <TextButton onClick={onOpenPriceHistory}>Sjá verðsögu</TextButton>
        </PriceHistoryBtnWrapper>
      )}
    </PricingWrapper>
  )
}

interface Props {
  user: User
  variant: VariantDetail
  inventory: Inventory[]
  userPrice: AugmentedUserPrice
  onShowProductDetails: () => void
  onToggleEnergyImageModal: () => void
}

export default function ProductInfo({
  user,
  variant,
  inventory,
  userPrice,
  onShowProductDetails,
  onToggleEnergyImageModal,
}: Props): JSX.Element {
  const {promptAuth, setPromptAuth} = useAuth()
  const {isVariantInWishlist, toggleVariantInWishlist} = useWishlist()
  const {comparedSkus, addToComparison, removeFromComparison} = useComparison()

  const [priceHistoryOpen, setPriceHistoryOpen] = useState<boolean>(false)

  const inComparison = comparedSkus?.indexOf(variant.sku) !== -1

  const {
    sku,
    name,
    product,
    showcases,
    importantInfo,
    energyLabelImages,
    subscriptionStatus,
    isInStockWeb,
    isInStockDutyfree,
  } = variant

  const {attributes} = product

  const price = getListingPrice(variant)?.price || 0
  const lowestPrice = getListingPrice(variant)?.lowestPrice || 0
  const cityPrice = onDutyFree
    ? variant?.listings?.webshop?.price?.lowestPrice
    : null
  const cityBasePrice = onDutyFree
    ? variant?.listings?.webshop?.price?.price
    : null

  const outOfStockWeb = !(onDutyFree ? isInStockDutyfree : isInStockWeb)

  const activeProduct = !!variant?.listings?.[settings?.channel]?.active

  // const tagCheap = false // TODO
  // const tagNew = false // TODO
  // const tagBProd = false // TODO
  const brandLogo = undefined // TODO

  const openPriceHistory = () => {
    setPriceHistoryOpen(true)

    TagManager.dataLayer({
      dataLayer: {
        category: 'Price History',
        event: 'Opened',
        event_label: sku,
      },
      dataLayerName: 'dataLayer',
    })
  }

  return (
    <Wrapper>
      <PriceHistoryModal
        sku={sku}
        name={product.name}
        isOpen={priceHistoryOpen}
        onClose={() => setPriceHistoryOpen(false)}
      />

      <Row>
        <Col col={12} md={10}>
          <H1>{name}</H1>

          <BrandingWrapper>
            {!!brandLogo && <BrandLogo src={brandLogo} alt="brand-logo" />}
            <Sku>{sku}</Sku>
          </BrandingWrapper>

          <SeeMoreWrapper>
            <ProductRichText html={product.shortDescription} />
          </SeeMoreWrapper>

          <SellingPointsWrapper>
            {product.sellingPoints.map((point: string) => (
              <SellingPoint key={point} point={point} />
            ))}
          </SellingPointsWrapper>

          {importantInfo && (
            <Col col={12}>
              <NoticeSticker
                icon={ErrorWarningFillIcon}
                color="yellow"
                message={importantInfo}
              />
            </Col>
          )}

          <Row justifyContent="between" alignItems="end">
            <Col col={4}>
              <EnergyWrapper>
                {energyLabelImages.length ? (
                  <EnergyLabelImageLink onClick={onToggleEnergyImageModal}>
                    <EnergyLabel
                      energyClass={attributes['energyclass']}
                      oldEnergyClass={attributes['orkuflokkur']}
                    />
                  </EnergyLabelImageLink>
                ) : (
                  <EnergyLabel
                    energyClass={attributes['energyclass']}
                    oldEnergyClass={attributes['orkuflokkur']}
                  />
                )}

                {!!energyLabelImages.length && (
                  <TextButton onClick={onShowProductDetails}>
                    Vöruupplýsingablað
                  </TextButton>
                )}
              </EnergyWrapper>
            </Col>

            <Col col={8}>
              {activeProduct && (
                <Row justifyContent="end">
                  <PriceInfo
                    sku={sku}
                    price={userPrice?.price?.price || price}
                    lowestPrice={userPrice?.lowestPrice || lowestPrice}
                    cityPrice={cityPrice}
                    cityBasePrice={cityBasePrice}
                    onOpenPriceHistory={openPriceHistory}
                  />
                </Row>
              )}
            </Col>
          </Row>

          {activeProduct && (
            <ActionsWrapper>
              <Row>
                <Col col={12} lg={6} md={12}>
                  <ButtonWrapper>
                    <Tooltip
                      id="wishlist"
                      content={
                        !user && (
                          <H6
                            style={{cursor: 'pointer'}}
                            onClick={() => setPromptAuth(!promptAuth)}
                          >
                            Skráðu þig inn hér til þess að bæta vöru á óskalista
                          </H6>
                        )
                      }
                      disableHover
                    >
                      <IconButton
                        className={cn('wishlist')}
                        icon={
                          isVariantInWishlist(sku)
                            ? HeartFillIcon
                            : HeartLineIcon
                        }
                        accentColor="pink"
                        backgroundColor="white"
                        large
                        onClick={() =>
                          !!user && toggleVariantInWishlist(variant)
                        }
                      />
                    </Tooltip>

                    <CheckButton
                      className={cn('comparison')}
                      checked={inComparison}
                      onClick={
                        inComparison
                          ? () => removeFromComparison(sku)
                          : () => addToComparison(variant)
                      }
                    >
                      Setja í samanburð
                    </CheckButton>
                  </ButtonWrapper>
                </Col>

                <Col col={12} lg={6} md={12}>
                  <AddToCartButton variant={variant} />
                </Col>
              </Row>
            </ActionsWrapper>
          )}

          {!onDutyFree && outOfStockWeb && (
            <NotificationSubscription sku={sku} status={subscriptionStatus} />
          )}

          {activeProduct && (
            <Margin>
              <ProductStock
                sku={sku}
                inventory={inventory}
                showcases={showcases}
                showShowcases={!!product.collectAtWarehouse}
              />
            </Margin>
          )}

          <Margin>
            <QsPricer variant={variant} />
          </Margin>
        </Col>
      </Row>
    </Wrapper>
  )
}
