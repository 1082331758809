import fromPairs from 'lodash/fromPairs'
import useSWRImmutable from 'swr/immutable'

import {onDutyFree} from '@festi/common/constants/channels'
import {useAuth} from '@festi/common/contexts'

import {handleRestResponse, restApi, UserPrice} from '../api/rest'

export function fetchPriceList(sku: string) {
  return handleRestResponse(restApi.pricesList({sku: [sku], page_size: 500}))
}

export interface AugmentedUserPrice extends UserPrice {
  discount: number
}

export type UserPriceRecord = Record<string, AugmentedUserPrice>

export interface UserPricesHookResult {
  data: UserPriceRecord
  loading: boolean
}

export function useUserPrices(skuList?: string[]): UserPricesHookResult {
  const {idToken} = useAuth()

  const skus = skuList?.join(',')

  const fetchData = idToken && skus && !onDutyFree

  const {data, isValidating} = useSWRImmutable(
    fetchData ? ['user-prices-list', skus] : null,
    ([_, skus]) => fetchPriceList(skus),
  )

  const priceList = data?.results
  return {
    data: priceList
      ? fromPairs(
          priceList.map((p) => [
            p.sku,
            p.price && p.lowestPrice !== null
              ? {
                  ...p,
                  discount: (p.price.price - p.lowestPrice) / p.price.price,
                }
              : { ...p, discount: null },
          ]),
        )
      : {},
    loading: isValidating,
  }
}

export interface SingleUserPriceHookResult {
  data?: AugmentedUserPrice
  loading: boolean
}

export function useSingleUserPrice(sku?: string): SingleUserPriceHookResult {
  const userPrices = useUserPrices([sku || ''])

  return {
    data: userPrices.data[sku || ''],
    loading: userPrices.loading,
  }
}
